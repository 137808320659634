
import { Component, Vue } from 'vue-property-decorator'
import echarts from 'echarts'

@Component({
  name: 'Analysis'
})
export default class Analysis extends Vue {
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private dayTotalInSum = 0
  private manDayTotalIn = 0
  private womanDayTotalIn = 0
  private echart: any

  get toiletName () {
    return this.$route.query.toiletName as string
  }

  get toiletId () {
    return this.$route.params.toiletId as string
  }

  $refs!: {
    echart: any;
  }

  created () {
    this.initTime()
    this.getData()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    this.echart && this.echart.dispose()
    window.removeEventListener('resize', this.resize)
  }

  initTime () {
    this.searchInfo.dateRange = [this.$dayjs().subtract(29, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
  }

  getData (): void {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.$axios.get(this.$apis.toilet.selectSmartToiletAnalysis, {
        toiletId: this.toiletId,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1]
      }).then((res) => {
        this.dayTotalInSum = res.dayTotalInSum || 0
        this.manDayTotalIn = res.manDayTotalIn || 0
        this.womanDayTotalIn = res.womanDayTotalIn || 0
        this.draw(res.dateList || [], res.dayTotalInTrend || [], res.manDayTotalInTrend || [], res.womanDayTotalInTrend || [])
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  draw (xData: Array<string>, yData1: Array<string>, yData2: Array<string>, yData3: Array<string>) {
    this.$nextTick(() => {
      this.echart = echarts.init(this.$refs.echart)
      const option: any = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: 80,
          left: 10,
          right: 30,
          bottom: 0,
          containLabel: true
        },
        legend: {
          icon: 'rect',
          top: 40,
          left: 0,
          itemWidth: 20,
          itemHeight: 1,
          itemGap: 5,
          textStyle: {
            color: '#8C8C8C'
          }
        },
        backgroundColor: 'transparent',
        color: ['#2C8EFF', '#53C8D1', '#FF6AD4'],
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: '#999999'
            }
          },
          data: xData
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: '总客流',
          type: 'line',
          symbolSize: 0,
          smooth: true,
          itemStyle: {
            normal: {
              borderWidth: 2
            }
          },
          lineStyle: {
            width: 4
          },
          data: yData1
        }, {
          name: '男厕客流',
          type: 'line',
          symbolSize: 0,
          smooth: true,
          itemStyle: {
            normal: {
              borderWidth: 2
            }
          },
          lineStyle: {
            width: 4
          },
          data: yData2
        }, {
          name: '女厕客流',
          type: 'line',
          symbolSize: 0,
          smooth: true,
          itemStyle: {
            normal: {
              borderWidth: 2
            }
          },
          lineStyle: {
            width: 4
          },
          data: yData3
        }]
      }

      this.echart.setOption(option)
    })
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }
}
